import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Ai } from "../../Assests/SVG/OthersMixed/ai.svg";
import { ReactComponent as GambitCyber } from "../../Assests/SVG/OthersMixed/gambitCyberFinalLogo.svg";
import { ReactComponent as ProfileIcon } from "../../Assests/SVG/OthersMixed/profile-circle.svg";
import CustomToggle from "../../Components/Custom/CustomToggle";
import CustomTooltip from "../../Components/Custom/CustomTooltip";
import { sideBarListColor } from "../../Constants/Constant";
import { sideBarList } from "../../Constants/sideBarListConstant";
import useToastify from "../../Hooks/useToastify";
import {
  getIsAiEnabled,
  getUser,
  setIsAiEnabled,
} from "../../redux/Slice/Auth/authSlice";
import { authSignOut } from "../../Services/Auth/Auth.service";
import SideBarListItem from "./SideBarList";

const drawerWidth = "20%";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  //     "& .MuiButtonBase-root": {
  //       gap: "2rem",
  //   },
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 2),
  height: "6rem",
  "& .drawer-header-text": {
    fontSize: "1.125rem",
    fontWeight: "600",
    lineHeight: "0.5rem",
  },
  "& .drawer-header-logo": {
    width: "15rem",
    height: "6rem",
  },
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  margin: "1rem",
  "& .MuiPaper-root": {
    backgroundColor: `${sideBarListColor.BACKGROUND_COLOR} !important`,
    color: `${sideBarListColor.TEXT} !important`,
    margin: "1rem",
    border: "0.063rem solid #1E2B40",
    borderRadius: "0.375rem",
    height: "95%",
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  "& .list-container": {
    margin: "0.5rem",
  },
}));
const iconStyles = {
  fill: sideBarListColor.TEXT,
  border: "0.063rem solid #1E2B40",
  borderRadius: "0.375rem",
  background: "#FFFFFF1A",
};

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useToastify();

  const user = useSelector(getUser);
  const isAiEnabled = useSelector(getIsAiEnabled) || false;

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(null);

  const handleDrawerClose = () => {
    setOpen((pre) => !pre);
  };

  const handleSignOut = async () => {
    const response = await dispatch(authSignOut({}));
    if (response.type === "auth/signOut/fulfilled") {
      showToast("You have been signed out successfully.", { type: "success" });
      navigate("/login");
    } else {
      showToast("Something went wrong. Please try again.", { type: "error" });
    }
  };

  const getSideBarClass = (isOpen) =>
    `sideBar-constats ${isOpen ? "" : "sideBar-constats-open"}`;

  const getStyles = (isOpen) => ({
    transition: "all 0.25s ease-out",
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? "scale(1)" : "scale(0)",
    display: isOpen ? "block" : "none",
    width: isOpen ? "auto" : "0",
  });
  const getAiStyles = (isOpen, isAiEnabled) => ({
    "& .MuiTypography-root": {
      color: "#fff !important",
    },
    transition: "gap 0.5s",
    gap: isOpen ? "1rem" : "5rem",
    width: !isOpen && isAiEnabled ? "70%" : "95%",
    paddingLeft: "0.5rem",
  });

  const getAiClassName = (isOpen, isAiEnabled) =>
    `sideBar-constats ${!isOpen ? "sideBar-constats-open" : ""} ${
      isAiEnabled ? "item-selected" : ""
    }`;

  let tooltipTitle = "";

  if (!open) {
    tooltipTitle = isAiEnabled ? "Disable AI" : "Enable AI";
  }
  return (
    <Drawer variant="permanent" open={open}>
      <Box className="sidebar-drawer-container">
        <Box>
          <DrawerHeader>
            <Box sx={getStyles(open)} className="drawer-header-text">
              <GambitCyber className="drawer-header-logo" />
            </Box>
            <IconButton onClick={handleDrawerClose}>
              {open ? (
                <ChevronRightIcon sx={iconStyles} />
              ) : (
                <ChevronLeftIcon sx={iconStyles} />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ background: "#1E2B40", marginBottom: "0.5rem" }} />
          <div className="list-container">
            {sideBarList &&
              sideBarList.length > 0 &&
              sideBarList.map((listItem, index) => {
                const uniquekey = `${listItem.id}-${index}`;
                const isExpandMore =
                  listItem?.children && listItem.children.length > 0;
                return (
                  <SideBarListItem
                    setSideBarOpen={setOpen}
                    key={uniquekey}
                    active={active}
                    setActive={setActive}
                    listItem={listItem}
                    sideBarOpen={open}
                    isExpandMore={isExpandMore}
                  />
                );
              })}
            {user?.isSignedIn ? (
              <CustomTooltip
                title={open ? "" : "Log out"}
                placement="right-end"
              >
                <ListItemButton
                  sx={{
                    "& .MuiTypography-root": {
                      color: "#fff !important",
                    },
                  }}
                  onClick={handleSignOut}
                  className={`sideBar-constats ${
                    open ? "" : "sideBar-constats-open"
                  }`}
                  component="nav"
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "0%",
                    }}
                  >
                    <LogoutIcon sx={{ fill: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText primary={"Log out"} />
                </ListItemButton>
              </CustomTooltip>
            ) : (
              <CustomTooltip title={open ? "" : "Log In"} placement="right-end">
                <ListItemButton
                  sx={{
                    "& .MuiTypography-root": {
                      color: "#fff !important",
                    },
                  }}
                  onClick={() => {
                    navigate("/login");
                  }}
                  className={`sideBar-constats ${
                    open ? "" : "sideBar-constats-open"
                  }`}
                  component="nav"
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "0%",
                    }}
                  >
                    <LoginIcon sx={{ fill: "#fff" }} />
                  </ListItemIcon>
                  <ListItemText primary={"Log In"} />
                </ListItemButton>
              </CustomTooltip>
            )}
          </div>
          {/* <Divider /> */}
        </Box>
        <Box
          // className="list-container"
          sx={{
            listStyle: "none",
            margin: "0 0.5rem",
            padding: "0",
            position: "relative",
            paddingTop: "8px",
            paddingBottom: "8px",
            width: "100%",
            maxWidth: "360px",
            color: "#FFF",
          }}
        >
          <CustomTooltip title={tooltipTitle} placement="right-end">
            <ListItemButton
              sx={getAiStyles(open, isAiEnabled)}
              onClick={() => {
                dispatch(setIsAiEnabled());
              }}
              className={getAiClassName(open, isAiEnabled)}
              component="nav"
            >
              <ListItemIcon
                sx={{
                  minWidth: "0%",
                }}
              >
                <Ai
                  style={{
                    height: "2rem",
                    width: "2rem",
                    fill: isAiEnabled ? "#0082F9" : "#ffffff",
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={isAiEnabled ? "Disable AI" : "Enable AI"}
              />
              <CustomToggle
                color="primary"
                inputProps={{ "aria-label": "controlled" }}
                checked={isAiEnabled}
                sx={{
                  "& .base-Switch-root": {
                    margin: "0rem 0.5rem !important",
                  },
                }}
              />
            </ListItemButton>
          </CustomTooltip>
          <CustomTooltip title={user?.name} placement="right-end">
            <ListItemButton
              sx={{
                "& .MuiTypography-root": {
                  color: "#fff !important",
                },
              }}
              onClick={() => {}}
              className={getSideBarClass(open)}
              component="nav"
            >
              <ListItemIcon
                sx={{
                  minWidth: "0%",
                }}
              >
                <ProfileIcon style={{}} />
              </ListItemIcon>
              <ListItemText primary={user?.name} />
            </ListItemButton>
          </CustomTooltip>
        </Box>
      </Box>
    </Drawer>
  );
};

export default SideBar;
