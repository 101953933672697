import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";

export const getSunBurstGraph = createAsyncThunk(
  "riskMatrix/getSunBurstGraph",
  async ({ selectedIds }, { rejectWithValue }) => {
    try {
      const requestIds =
        selectedIds &&
        selectedIds.length > 0 &&
        selectedIds.map((item) => item.id);
      const requestObject = { ids: requestIds };
      const response = await axiosWrapper.post(
        `tid/risk-profile/sunburst`,
        requestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getRiskProfile = createAsyncThunk(
  "riskMatrix/getRiskProfile",
  async ({ selectedIds, tabFunction }, { rejectWithValue }) => {
    try {
      const requestIds =
        selectedIds &&
        selectedIds.length > 0 &&
        selectedIds.map((item) => item.id);
      const requestObject = { ids: requestIds };
      let url = `tid/risk-profile`;
      if (tabFunction) {
        url += `?function=${tabFunction}`;
      }
      const response = await axiosWrapper.post(url, requestObject);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addControl = createAsyncThunk(
  "riskMatrix/addControl",
  async ({ threatProfileId, data }, { rejectWithValue }) => {
    try {
      const url = threatProfileId
        ? `tid/threat-profile/baseline/add/${threatProfileId}`
        : `tid/threat-profile/baseline/add`;

      const response = await axiosWrapper.post(url, data);
      return response;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.message : "An unexpected error occurred"
      );
    }
  }
);

export const removeControl = createAsyncThunk(
  "riskMatrix/removeControl",
  async ({ threatProfileId, data }, { rejectWithValue }) => {
    try {
      const url = threatProfileId
        ? `tid/threat-profile/baseline/remove/${threatProfileId}`
        : `tid/threat-profile/baseline/remove`;

      const response = await axiosWrapper.post(url, data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
