import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "@xyflow/react/dist/style.css";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { configureAmplify } from "./helper/amplify";
import router from "./routes/Router";

const CloseButton = ({ closeToast }) => (
  <i
    className="material-icons"
    onClick={closeToast}
    onKeyDown={() => {}}
    aria-hidden
  >
    <CloseOutlinedIcon sx={{ fill: "#fff" }} />
  </i>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
};
function App() {
  useEffect(() => {
    configureAmplify();
  }, []);

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer closeButton={CloseButton} />
    </>
  );
}

export default App;
