import { createSlice } from "@reduxjs/toolkit";
import {
  createBulkIndicator,
  createIndicator,
  getIndicatorById,
  getIndicatorCount,
  getIndicators,
  getTechniquesSummary,
  getTypeOfIndicators,
  updateIndicator,
} from "../../../Services/Indicators/indicators.service";

const initialState = {
  loading: {
    getIndicators: false,
    getIndicatorCount: false,
    getTypeOfIndicators: false,
    getTechniquesSummary: false,
    createIndicator: false,
    updateIndicator: false,
    getIndicatorById: false,
  },
  error: {
    getIndicators: null,
    getIndicatorCount: null,
    getTypeOfIndicators: null,
    getTechniquesSummary: null,
    createIndicator: null,
    updateIndicator: null,
    getIndicatorById: null,
  },
  allIndicators: [],
  indicatorCount: {},
  typeOfIndicators: {},
  pagination: {
    currentPage: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    lastPage: false,
  },
  indicatorFilterObject: {
    status: "",
    confidence: "",
    fromDate: "",
    toDate: "",
    searchValue: "",
    type: "",
  },
  createIndiactorObject: {},
  bulkCreatePreview: [],
};

const indicatorSlice = createSlice({
  initialState,
  name: "indicators",
  reducers: {
    setIndicatorFilter: (state, action) => ({
      ...state,
      indicatorFilterObject: {
        ...state.indicatorFilterObject,
        ...action.payload,
      },
    }),
    resetIndicatorFilter: (state) => ({
      ...state,
      indicatorFilterObject: initialState.indicatorFilterObject,
    }),
    setCreateIndicatorObject: (state, action) => ({
      ...state,
      createIndiactorObject: action.payload,
    }),
    resetCreateIndicatorObject: (state) => ({
      ...state,
      createIndiactorObject: initialState.createIndiactorObject,
    }),
    resetIndicators: (state) => ({
      ...state,
      allIndicators: initialState.allIndicators,
    }),
  },
  extraReducers: (builder) =>
    builder
      .addCase(getIndicators.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getIndicators: true,
        },
        error: {
          ...state.error,
          getIndicators: null,
        },
      }))
      .addCase(getIndicators.fulfilled, (state, action) => {
        const { response, page, loadmore } = action.payload;
        return {
          ...state,
          loading: {
            ...state.loading,
            getIndicators: false,
          },
          allIndicators: loadmore
            ? [...state.allIndicators, ...response.content]
            : response.content,
          pagination: {
            ...state.pagination,
            currentPage: response?.number || page,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            lastPage: response.last,
          },
          error: {
            ...state.error,
            getIndicators: null,
          },
        };
      })
      .addCase(getIndicators.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getIndicators: false,
        },
        allIndicators: initialState.allIndicators,
        error: {
          ...state.error,
          getIndicators: action.payload,
        },
      }))
      .addCase(getIndicatorCount.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getIndicatorCount: true,
        },
      }))
      .addCase(getIndicatorCount.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getIndicatorCount: false,
        },
        indicatorCount: action.payload,
      }))
      .addCase(getIndicatorCount.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getIndicatorCount: false,
        },
        indicatorCount: initialState.indicatorCount,
        error: {
          ...state.error,
          getIndicatorCount: action.payload,
        },
      }))
      .addCase(getTypeOfIndicators.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getTypeOfIndicators: true,
        },
      }))
      .addCase(getTypeOfIndicators.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getTypeOfIndicators: false,
        },
        typeOfIndicators: action.payload,
      }))
      .addCase(getTypeOfIndicators.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getTypeOfIndicators: false,
        },
        typeOfIndicators: initialState.typeOfIndicators,
        error: {
          ...state.error,
          getTypeOfIndicators: action.payload,
        },
      }))
      .addCase(getTechniquesSummary.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getTechniquesSummary: true,
        },
      }))
      .addCase(getTechniquesSummary.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getTechniquesSummary: false,
        },
      }))
      .addCase(getTechniquesSummary.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getTechniquesSummary: false,
        },
        error: {
          ...state.error,
          getTechniquesSummary: action.payload,
        },
      }))
      .addCase(createIndicator.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          createIndicator: true,
        },
      }))
      .addCase(createIndicator.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          createIndicator: false,
        },
      }))
      .addCase(createIndicator.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          createIndicator: false,
        },
        error: {
          ...state.error,
          createIndicator: action.payload,
        },
      }))
      .addCase(updateIndicator.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          updateIndicator: true,
        },
      }))
      .addCase(updateIndicator.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          updateIndicator: false,
        },
      }))
      .addCase(updateIndicator.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          updateIndicator: false,
        },
        error: {
          ...state.error,
          updateIndicator: action.payload,
        },
      }))
      .addCase(createBulkIndicator.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          createIndicator: true,
        },
      }))
      .addCase(createBulkIndicator.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          createIndicator: false,
        },
        bulkCreatePreview: action.payload,
      }))
      .addCase(createBulkIndicator.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          createIndicator: false,
        },
        bulkCreatePreview: initialState.bulkCreatePreview,
        error: {
          ...state.error,
          createIndicator: action.payload,
        },
      }))
      .addCase(getIndicatorById.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getIndicatorById: true,
        },
      }))
      .addCase(getIndicatorById.fulfilled, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getIndicatorById: false,
        },
        createIndiactorObject: action.payload,
      }))
      .addCase(getIndicatorById.rejected, (state, action) => ({
        ...state,
        loading: {
          ...state.loading,
          getIndicatorById: false,
        },
        createIndiactorObject: initialState.createIndiactorObject,
        error: {
          ...state.error,
          getIndicatorById: action.payload,
        },
      })),
});

export const getAllIndicatorsData = (state) => state.indicators.allIndicators;
export const getIndicatorsPagination = (state) => state.indicators.pagination;
export const getIndicatorsLoading = (state) =>
  state.indicators.loading.getIndicators;
export const getCreateIndicatorLoading = (state) =>
  state.indicators.loading.createIndicator;
export const getIndicatorsError = (state) =>
  state.indicators.error.getIndicators;
export const getIndicatorCountData = (state) => state.indicators.indicatorCount;
export const getTechniquesSummaryLoading = (state) =>
  state.indicators.loading.getTechniquesSummary;
export const getTypeOfIndicatorsData = (state) =>
  state.indicators.typeOfIndicators;
export const getIndicatorFilterObject = (state) =>
  state.indicators.indicatorFilterObject;
export const getCreateIndiactorObject = (state) =>
  state.indicators.createIndiactorObject;
export const getBulkCreatePreviewData = (state) =>
  state.indicators.bulkCreatePreview;
export const getIndicatorByIdLoading = (state) =>
  state.indicators.loading.getIndicatorById;

export const {
  setIndicatorFilter,
  resetIndicatorFilter,
  setCreateIndicatorObject,
  resetCreateIndicatorObject,
  resetIndicators,
} = indicatorSlice.actions;

export default indicatorSlice.reducer;
