import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";

export const getUserNotifications = createAsyncThunk(
  "notifications/getUserNotifications",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        "/user-service/users/user/notification"
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
