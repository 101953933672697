import React from "react";
import { Outlet } from "react-router-dom";
import { tourSteps } from "../Constants/tourSteps";
import useTour from "../Hooks/useTour";
import FeedbackForm from "./FeedbackForm";
import Header from "./header";
import "./index.css";
import SideBar from "./sidebar";

const Layout = () => {
  const tour = useTour(tourSteps);

  return (
    <div className="main-body-container">
      <div className="main-body-sections-container">
        <SideBar />
        <div className="main-container">
          <Header />
          <Outlet />
        </div>
      </div>
      <FeedbackForm />
      {tour}
    </div>
  );
};

export default Layout;
