export const tourSteps = [
  {
    target: "body",
    placement: "center",
    content:
      "Welcome to Gambit Cyber's KnightGuard Platform. Your Go To Platform for Proactive Security Ops. Let us quickly take you on a Tour to the core functionalities of the Platform.",
  },
  {
    target: ".threat-profile",
    content:
      "Threat Profile provides you the ability to define a prioritized list of Threat Behaviours and the most important Defensive Controls an Organisation should have in place. We use NIST 800-53 to Map Threat Behaviours to Defensive Measures. You can set up your organisation's Threat Profile in less than 2 minutes, by simply uploading a prioeritized list of MITRE ATT&CK Techniques. You always get the option to customise your Threat Profile at any given point in time.",
  },
  {
    target: ".dashboard",
    content:
      "Once you have defined a Threat Profile for your organisation, you can navigate to Dashboard. Dashboard provides you, among other things, High Level overiew of the most important Threats and Controls specific to your Organisation. You can also customise the Dahsboard.",
  },
  {
    target: ".intel-flow",
    content:
      "Intel Flow is the starting point for you to Prioritize Threats that are relevant to you and then take appropriate Remediation Actions. The first step in the Intel Flow is Threat Detail Screen.",
  },
  {
    target: ".threat-detail",
    content:
      "Threat Details screen gives you the ability to list all the Threats that are relevant to an organisation. A Threat could be a Threat Actor, Threat Group, Malware, Tool that can be used maliciously or even Threat Intel Reports.",
  },
  {
    target: ".threat-detail-filtering",
    content:
      "You can filter Threats relevant to you using our comprehensive Filtering Criteria. Free Text filtering works the best in most cases.",
  },
  {
    target: ".threat-detail-monitroing",
    content:
      "You can also monitor specific Threats and everytime there is a change in Threat Behaviour, we will promptly notify you.",
  },
  {
    target: ".threat-detail-private-intel",
    content:
      "This is the list of Private Threat Intel that is visible only to members within your organisation. You can bring your own intel in the platform by Navigating to Create.",
  },
  {
    target: ".threat-detail-private-create",
    content:
      "As a user of the platform, you get the ability to Bring Your Own Intel into the platform. You can simply click on Create and you get an option to create diffiernt types of Threat Intel Objects right into the platform.",
  },
  {
    target: ".threat-detail-private-create-2",
    content:
      "Creating a Threat Intel Object should not be a tedious Job. Therefore we have created an AI-Enabled Data Creation Pipeline. Simply Copy/paste the link to your favourite Security Blog, Advisory or Report and we will automatically extract relevant information and convert it into Actionable Intelligence, in less than 10 min.",
  },
  {
    target: ".attack-matrix",
    content:
      "Once you have identified the Threat you will work with, you can navigate to Attack Matrix screen to understand what are the TOP Threat Techniques you should focus on. Here you also get to see the details on how a specific Technique was used by the selected Threat Entities",
  },
  {
    target: ".remediations",
    content:
      "This is your place to understand what Defensive measures you should have in place in order to mitigate and defend against the Threat. You get Actionable Remediation plan that can be assigned to either yourself or your team members. You also get to track your Threat Exposure in real time.",
  },
  {
    target: ".death",
    content:
      "DE&TH stands for Detection Engineering and Threat Hunting. Here you can download Detection Rules in multiple languages for relevant threats. You also can use our AI-Enabled Threat Detection and Hunt Query Generator.",
  },
  {
    target: ".risk-matrix",
    content:
      "Finally you can visualise how your Threat Posture maps to your Risk Profile. We enable this by mapping Threats to NIST CSF Framework. If you are a CISO, then this also helps you gauage the effectiveness and maturity of your Security Program.",
  },
  {
    target: "body",
    placement: "center",
    content:
      "There is a lot more functionality available in the Platform. In case you do not understand anything, do not hesitate to contact us. Have a Great Time Hunting.",
  },
];
