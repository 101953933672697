import { createSlice } from "@reduxjs/toolkit";
import {
  createDetectionRule,
  getDetectionComponentGraph,
  getDetectionInfo,
  getDetectionQueries,
  getDetectionRuleById,
  getDetectionRulesBySearch,
  getDetectionRulesByTechniqueId,
  getHuntQueries,
  updateSensorEventStatus,
} from "../../../Services/TID/detection.service";

const initialState = {
  loading: false,
  putLoading: false,
  detectionRuleLoading: false,
  createRuleLoading: false,
  detectionInfoData: [],
  detectionComponentGraph: [],
  detectionError: null,
  huntQueryData: "",
  huntQueryError: null,
  detectionRuleError: null,
  detectionActualRuleData: {},
  detectionRulesLoading: false,
  detectionRulesData: [],
  detectionRulesError: null,
  rulesPagination: {
    currentPage: 0,
    pageSize: 20,
    totalElements: 0,
    totalPages: 0,
    lastPage: false,
  },
};

const detectionSlice = createSlice({
  name: "detection",
  initialState,
  reducers: {
    resetDetectionInfoData: (state, action) => ({
      ...state,
      loading: false,
      detectionInfoData: [],
      detectionInfoError: null,
    }),
    resetHuntQueryData: (state, action) => ({
      ...state,
      loading: false,
      huntQueryData: "",
      huntQueryError: null,
    }),
    resetDetectionRule: (state, action) => ({
      ...state,
      detectionRuleLoading: false,
      detectionActualRuleData: [],
      detectionRuleError: null,
    }),
    resetDetectionRules: (state, action) => ({
      ...state,
      detectionRulesLoading: false,
      detectionRulesData: [],
      detectionRulesError: null,
      rulesPagination: initialState.rulesPagination,
    }),
    setDetectionRuleError: (state, action) => ({
      ...state,
      detectionRuleError: null,
      createRuleLoading: false,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDetectionInfo.pending, (state) => ({
        ...state,
        loading: true,
        detectionInfoError: null,
      }))
      .addCase(getDetectionInfo.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        detectionInfoData: action.payload,
        detectionInfoError: null,
      }))
      .addCase(getDetectionInfo.rejected, (state, action) => ({
        ...state,
        loading: false,
        detectionInfoData: [],
        detectionInfoError: action.payload,
      }))
      .addCase(getHuntQueries.pending, (state) => ({
        ...state,
        loading: true,
        huntQueryError: null,
      }))
      .addCase(getHuntQueries.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        huntQueryData: action.payload,
        huntQueryError: null,
      }))
      .addCase(getHuntQueries.rejected, (state, action) => ({
        ...state,
        loading: false,
        huntQueryData: "",
        huntQueryError: action.payload,
      }))
      .addCase(getDetectionQueries.pending, (state) => ({
        ...state,
        loading: true,
        huntQueryError: null,
      }))
      .addCase(getDetectionQueries.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        huntQueryData: action.payload,
        huntQueryError: null,
      }))
      .addCase(getDetectionQueries.rejected, (state, action) => ({
        ...state,
        loading: false,
        huntQueryData: "",
        huntQueryError: action.payload,
      }))
      .addCase(getDetectionComponentGraph.pending, (state) => ({
        ...state,
        loading: true,
        detectionError: null,
      }))
      .addCase(getDetectionComponentGraph.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        detectionComponentGraph: action.payload,
        detectionError: null,
      }))
      .addCase(getDetectionComponentGraph.rejected, (state, action) => ({
        ...state,
        loading: false,
        detectionComponentGraph: [],
        detectionError: action.payload,
      }))
      .addCase(updateSensorEventStatus.pending, (state) => ({
        ...state,
        putLoading: true,
      }))
      .addCase(updateSensorEventStatus.fulfilled, (state, action) => ({
        ...state,
        putLoading: false,
      }))
      .addCase(updateSensorEventStatus.rejected, (state, action) => ({
        ...state,
        putLoading: false,
      }))
      .addCase(getDetectionRulesByTechniqueId.pending, (state) => ({
        ...state,
        detectionRulesLoading: true,
        detectionRulesError: null,
      }))
      .addCase(getDetectionRulesByTechniqueId.fulfilled, (state, action) => {
        const { response, page, loadmore } = action.payload;
        return {
          ...state,
          detectionRulesLoading: false,
          detectionRulesData: loadmore
            ? [...state.detectionRulesData, ...response.content]
            : response.content,
          detectionRulesError: null,
          rulesPagination: {
            ...state.rulesPagination,
            currentPage: page,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            lastPage: response.last,
          },
        };
      })
      .addCase(getDetectionRulesByTechniqueId.rejected, (state, action) => ({
        ...state,
        detectionRulesLoading: false,
        detectionRulesData: [],
        detectionRulesError: action.payload,
      }))
      .addCase(getDetectionRulesBySearch.pending, (state) => ({
        ...state,
        detectionRulesLoading: true,
        detectionRulesError: null,
      }))
      .addCase(getDetectionRulesBySearch.fulfilled, (state, action) => {
        const { response, page, loadmore } = action.payload;
        return {
          ...state,
          detectionRulesLoading: false,
          detectionRulesData: loadmore
            ? [...state.detectionRulesData, ...response.content]
            : response.content,
          detectionRulesError: null,
          rulesPagination: {
            ...state.rulesPagination,
            currentPage: page,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            lastPage: response.last,
          },
        };
      })
      .addCase(getDetectionRulesBySearch.rejected, (state, action) => ({
        ...state,
        detectionRulesLoading: false,
        detectionRulesData: [],
        detectionRulesError: action.payload,
      }))
      .addCase(getDetectionRuleById.pending, (state) => ({
        ...state,
        detectionRuleLoading: true,
        detectionRuleError: null,
      }))
      .addCase(getDetectionRuleById.fulfilled, (state, action) => ({
        ...state,
        detectionRuleLoading: false,
        detectionActualRuleData: action.payload,
        detectionRuleError: null,
      }))
      .addCase(getDetectionRuleById.rejected, (state, action) => ({
        ...state,
        detectionRuleLoading: false,
        detectionActualRuleData: {},
        detectionRuleError: action.payload,
      }))
      .addCase(createDetectionRule.pending, (state) => ({
        ...state,
        createRuleLoading: true,
        detectionRuleError: null,
      }))
      .addCase(createDetectionRule.fulfilled, (state, action) => ({
        ...state,
        createRuleLoading: false,
        detectionRuleError: null,
      }))
      .addCase(createDetectionRule.rejected, (state, action) => ({
        ...state,
        createRuleLoading: false,
        detectionRuleError: action.payload,
      }));
  },
});

export const {
  resetDetectionInfoData,
  resetHuntQueryData,
  resetDetectionRule,
  resetDetectionRules,
  setDetectionRuleError,
} = detectionSlice.actions;
export default detectionSlice.reducer;

export const getDetectionInformationData = (state) =>
  state.detection.detectionInfoData;
export const getDetectionComponentGraphData = (state) =>
  state.detection.detectionComponentGraph;
export const getDetectionLoading = (state) => state.detection.loading;
export const getDetectionPutLoading = (state) => state.detection.putLoading;
export const gethuntQueryData = (state) => state.detection.huntQueryData;
export const getDetectionRulesData = (state) =>
  state.detection.detectionRulesData;
export const getDetectionRulesLoading = (state) =>
  state.detection.detectionRulesLoading;
export const getDetectionRulesPagination = (state) =>
  state.detection.rulesPagination;
export const getDetectionActualRule = (state) =>
  state.detection.detectionActualRuleData;
export const getDetectionRuleLoading = (state) =>
  state.detection.detectionRuleLoading;
export const getCreateRuleLoading = (state) =>
  state.detection.createRuleLoading;
export const getDetectionRuleError = (state) =>
  state.detection.detectionRuleError;
