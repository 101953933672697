import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";

export const getDetectionInfo = createAsyncThunk(
  "detection/getDetectionInfoData",
  async ({ selectedIds }, { rejectWithValue }) => {
    try {
      const requestIds =
        selectedIds &&
        selectedIds.length > 0 &&
        selectedIds.map((item) => item.id);
      const requestObject = { ids: requestIds };
      const response = await axiosWrapper.post(
        `tid/detection/summary`,
        requestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getDetectionCountPerTechnique = createAsyncThunk(
  "detection/getDetectionCountPerTechnique",
  async ({ selectedIds }, { rejectWithValue }) => {
    try {
      const requestIds =
        selectedIds &&
        selectedIds.length > 0 &&
        selectedIds.map((item) => item.id);
      const requestObject = { ids: requestIds };
      const response = await axiosWrapper.post(
        `/tid/detection/count`,
        requestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getHuntQueries = createAsyncThunk(
  "detection/getHuntQueries",
  async ({ type, data }, { rejectWithValue }) => {
    try {
      let response = await axiosWrapper.post(`ai/hunt?type=${type}`, data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getDetectionQueries = createAsyncThunk(
  "detection/getDetectionQueries",
  async ({ type, data }, { rejectWithValue }) => {
    try {
      let response = await axiosWrapper.post(
        `ai/detections?type=${type}`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getDetectionComponentGraph = createAsyncThunk(
  "detection/getDetectionComponentGraph",
  async ({ techniqueId }, { rejectWithValue }) => {
    try {
      let response = await axiosWrapper.get(
        `/tid/detection/technique/graph?techniqueId=${techniqueId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateSensorEventStatus = createAsyncThunk(
  "detection/updateSensorEventStatus",
  async ({ requestObject }, { rejectWithValue }) => {
    try {
      let response = await axiosWrapper.put(
        `/tid/detection/sensor_update`,
        requestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createDetectionRule = createAsyncThunk(
  "detection/createDetectionRule",
  async ({ requestObject }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(`/tid/detection`, requestObject);
      return response;
    } catch (error) {
      return rejectWithValue({ message: error.message[0], code: error.code });
    }
  }
);

export const getDetectionRuleById = createAsyncThunk(
  "detection/getDetectionRuleById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`tid/detection/${id}`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getDetectionRulesByTechniqueId = createAsyncThunk(
  "detection/getDetectionRulesByTechniqueId",
  async ({ techniqueIds, page = 0, source = "" }, { rejectWithValue }) => {
    try {
      let searchUrl = `/tid/detection/technique?techniqueIds=${techniqueIds}&page=${page}&size=20`;
      if (source) {
        searchUrl = searchUrl + `&source=${source}`;
      }
      const response = await axiosWrapper.get(searchUrl);
      return {
        response,
        page,
        loadmore: page > 0 && response.content && response.content.length > 0,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getDetectionRulesBySearch = createAsyncThunk(
  "detection/getDetectionRulesBySearch",
  async ({ text, page = 0, source = "" }, { rejectWithValue }) => {
    try {
      let searchUrl = `/tid/detection/search?text="${text}"&page=${page}&size=20`;
      if (source) {
        searchUrl = searchUrl + `&source=${source}`;
      }
      const response = await axiosWrapper.get(searchUrl);
      return {
        response,
        page,
        loadmore: page > 0 && response.content && response.content.length > 0,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
