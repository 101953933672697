import { useCallback, useEffect } from "react";

export const usePolling = (callback, interval) => {
  const tick = useCallback(() => {
    if (typeof callback === "function") {
      callback();
    }
  }, [callback]);

  useEffect(() => {
    tick();
    const intervalId = setInterval(tick, interval);
    return () => {
      clearInterval(intervalId);
    };
  }, [tick, interval]);
};
