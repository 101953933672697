import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSearchResults = createAsyncThunk(
  "workspace/getSearchResults",
  async ({ question, start_date, end_date }, { rejectWithValue }) => {
    try {
      let baseURL = `${process.env.REACT_APP_API_SEARCH_BASE_URL}/query?question=${question}`;
      if (start_date && end_date) {
        baseURL = baseURL + `&start_date=${start_date}&end_date=${end_date}`;
      }
      const response = await axios.get(baseURL);
      return { ...response?.data, searchValue: question };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
