import { createSlice } from "@reduxjs/toolkit";
import {
  deleteCampaignNode,
  getAllCampaigns,
  getCampaignById,
  getDeploymentsDetection,
  updateCampaignNode,
} from "../../../Services/Campaign/campaign.service";

const initialState = {
  loading: {
    getAllCampaignsLoading: false,
    getCampaignByIdLoading: false,
    updateCampaignNodeLoading: false,
    deleteCampaignNodeLoading: false,
    getDeploymentLoading: false,
  },
  campaignsList: [],
  campaign: {},
  pagination: {
    currentPage: 0,
    pageSize: 10,
    totalElements: 0,
    totalPages: 0,
    lastPage: false,
  },
  deploymentDetectionsList: [],
  campaignError: null,
};
const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCampaigns.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getAllCampaignsLoading: true,
        },
      }))
      .addCase(getAllCampaigns.fulfilled, (state, action) => {
        const { response, page, loadmore } = action.payload;
        return {
          ...state,
          loading: {
            ...state.loading,
            getAllCampaignsLoading: false,
          },
          campaignsList: loadmore
            ? [...state.campaignsList, ...response.content]
            : response.content,
          pagination: {
            ...state.pagination,
            currentPage: response?.number || page,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            lastPage: response.last,
          },
        };
      })
      .addCase(getAllCampaigns.rejected, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getAllCampaignsLoading: false,
        },
        campaignsList: [],
      }))
      .addCase(getCampaignById.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getCampaignByIdLoading: true,
        },
      }))
      .addCase(getCampaignById.fulfilled, (state, action) => {
        return {
          ...state,
          loading: {
            ...state.loading,
            getCampaignByIdLoading: false,
          },
          campaign: action.payload,
        };
      })
      .addCase(getCampaignById.rejected, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getCampaignByIdLoading: false,
        },
        campaign: {},
      }))
      .addCase(updateCampaignNode.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          updateCampaignNodeLoading: true,
        },
      }))
      .addCase(updateCampaignNode.fulfilled, (state) => {
        return {
          ...state,
          loading: {
            ...state.loading,
            updateCampaignNodeLoading: false,
          },
        };
      })
      .addCase(updateCampaignNode.rejected, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          updateCampaignNodeLoading: false,
        },
      }))
      .addCase(deleteCampaignNode.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          deleteCampaignNodeLoading: true,
        },
      }))
      .addCase(deleteCampaignNode.fulfilled, (state) => {
        return {
          ...state,
          loading: {
            ...state.loading,
            deleteCampaignNodeLoading: false,
          },
        };
      })
      .addCase(deleteCampaignNode.rejected, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          deleteCampaignNodeLoading: false,
        },
      }))
      .addCase(getDeploymentsDetection.pending, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getDeploymentLoading: true,
        },
      }))
      .addCase(getDeploymentsDetection.fulfilled, (state, action) => {
        return {
          ...state,
          loading: {
            ...state.loading,
            getDeploymentLoading: false,
          },
          deploymentDetectionsList: action.payload,
        };
      })
      .addCase(getDeploymentsDetection.rejected, (state) => ({
        ...state,
        loading: {
          ...state.loading,
          getDeploymentLoading: false,
        },
        deploymentDetectionsList: [],
      }));
  },
});

export default campaignSlice.reducer;

export const getCampaignsList = (state) => state.campaign.campaignsList;
export const getCampaignData = (state) => state.campaign.campaign;
export const getDeploymentDetectionsList = (state) =>
  state.campaign.deploymentDetectionsList;
export const getCampaignsLoading = (state) => state.campaign.loading;
