import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ReactComponent as KnightGaurd } from "../../Assests/SVG/OthersMixed/knight-gaurd.svg";
import NotificationsPopUp from "../../modules/notifications";
import {
  getUserTourSteps,
  setUserTourSteps,
} from "../../redux/Slice/Auth/authSlice";
import "../index.css";

const Header = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const userTourSteps = useSelector(getUserTourSteps);

  const showTourButton = location.pathname === "/intel-flow";

  return (
    <div className="main-header">
      <span className="header-title">
        <KnightGaurd style={{ width: "15rem", height: "3rem" }} />
      </span>
      <div
        style={{
          paddingRight: "5rem",
          display: "flex",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        {/* <CommonSearchHeader /> */}
        <NotificationsPopUp />
        {showTourButton && (
          <Button
            onClick={() =>
              dispatch(setUserTourSteps({ ...userTourSteps, run: true }))
            }
            variant="outlined"
            id="my-first-step"
            className="my-first-step"
          >
            Take a tour
          </Button>
        )}
      </div>
    </div>
  );
};

export default Header;
