import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";

export const createCampaign = createAsyncThunk(
  "campaign/createCampaign",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post("campaigns/create", data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getAllCampaigns = createAsyncThunk(
  "campaign/getAllCampaigns",
  async ({ page = 0 }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get("campaigns/fetch");
      return {
        response,
        page,
        loadmore: page > 0 && response.content && response.content.length > 0,
      };
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getCampaignById = createAsyncThunk(
  "campaign/getCampaignById",
  async ({ campaignId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`campaigns/${campaignId}`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateCampaign = createAsyncThunk(
  "campaign/updateCampaign",
  async ({ campaignId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.put(`campaigns/${campaignId}`, data);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addCampaignNode = createAsyncThunk(
  "campaign/addCampaignNode",
  async ({ campaignId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `campaigns/${campaignId}/node`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateCampaignNode = createAsyncThunk(
  "campaign/updateCampaignNode",
  async ({ campaignId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.put(
        `campaigns/${campaignId}/node`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCampaignNode = createAsyncThunk(
  "campaign/deleteCampaignNode",
  async ({ campaignId, nodeId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.delete(
        `/campaigns/${campaignId}/node/${nodeId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addCampaignEdge = createAsyncThunk(
  "campaign/addCampaignEdge",
  async ({ campaignId, data }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.post(
        `/campaigns/${campaignId}/edge`,
        data
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteCampaignEdge = createAsyncThunk(
  "campaign/deleteCampaignEdge",
  async ({ campaignId, edgeId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.delete(
        `/campaigns/${campaignId}/edge/${edgeId}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getDeploymentsDetection = createAsyncThunk(
  "campaign/getDeploymentsDetection",
  async ({ campaignId }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `campaigns/${campaignId}/detections`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
