import { createSlice } from "@reduxjs/toolkit";
import {
  addThreatfromTopThreat,
  getEffectiveSecurityPosture,
  getThreatCategoriesType,
  getTopCapabilities,
  getTopThreats,
  removeThreatfromTopThreat,
} from "../../../Services/TID/dashboard.service";

const initialState = {
  loading: false,
  error: null,
  sankeyGraphData: {},
  topEntities: [],
  topCapabilities: [],
  threatCategoriesType: [],
  effectiveSecurityPosture: "",
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTopThreats.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(getTopThreats.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        sankeyGraphData: action.payload?.sankeyDTO,
        topEntities: action.payload?.entities,
        error: null,
      }))
      .addCase(getTopThreats.rejected, (state, action) => ({
        ...state,
        loading: false,
        sankeyGraphData: initialState.sankeyGraphData,
        topEntities: initialState.topEntities,
        error: action.payload,
      }))
      .addCase(addThreatfromTopThreat.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(addThreatfromTopThreat.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        sankeyGraphData: action.payload?.sankeyDTO,
        topEntities: action.payload?.entities,
        error: null,
      }))
      .addCase(addThreatfromTopThreat.rejected, (state, action) => ({
        ...state,
        loading: false,
        sankeyGraphData: initialState.sankeyGraphData,
        topEntities: initialState.topEntities,
        error: action.payload,
      }))
      .addCase(removeThreatfromTopThreat.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(removeThreatfromTopThreat.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        sankeyGraphData: action.payload?.sankeyDTO,
        topEntities: action.payload?.entities,
        error: null,
      }))
      .addCase(removeThreatfromTopThreat.rejected, (state, action) => ({
        ...state,
        loading: false,
        sankeyGraphData: initialState.sankeyGraphData,
        topEntities: initialState.topEntities,
        error: action.payload,
      }))
      .addCase(getTopCapabilities.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(getTopCapabilities.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        topCapabilities: action.payload,
        error: null,
      }))
      .addCase(getTopCapabilities.rejected, (state, action) => ({
        ...state,
        loading: false,
        topCapabilities: initialState.topCapabilities,
        error: action.payload,
      }))
      .addCase(getEffectiveSecurityPosture.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(getEffectiveSecurityPosture.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        effectiveSecurityPosture: action.payload,
        error: null,
      }))
      .addCase(getEffectiveSecurityPosture.rejected, (state, action) => ({
        ...state,
        loading: false,
        effectiveSecurityPosture: initialState.effectiveSecurityPosture,
        error: action.payload,
      }))
      .addCase(getThreatCategoriesType.pending, (state) => ({
        ...state,
        loading: true,
        error: null,
      }))
      .addCase(getThreatCategoriesType.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        threatCategoriesType: action.payload,
        error: null,
      }))
      .addCase(getThreatCategoriesType.rejected, (state, action) => ({
        ...state,
        loading: false,
        threatCategoriesType: initialState.threatCategoriesType,
        error: action.payload,
      }));
  },
});
export default dashboardSlice.reducer;

export const getDashboardLoading = (state) => state.dashboard.loading;
export const getDashboardSankeyGraphData = (state) =>
  state.dashboard?.sankeyGraphData;
export const getDashboardTopEntities = (state) => state.dashboard.topEntities;
export const getDashboardTopCapabilities = (state) =>
  state.dashboard.topCapabilities;
export const getDashboardThreatCategoriesType = (state) =>
  state.dashboard.threatCategoriesType;
export const getDashboardEffectiveSecurityPosture = (state) =>
  state.dashboard.effectiveSecurityPosture;
