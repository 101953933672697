import { LoadingButton } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CustomLoadingButton = ({ children, loading, sx, ...rest }) => {
  return (
    <LoadingButton
      loadingIndicator={<CircularProgress size={20} />}
      loading={loading}
      variant="contained"
      sx={{
        "&.Mui-disabled": {
          color: "#8E97A4 !important",
          boxShadow: "none",
          backgroundColor: "rgba(0, 0, 0, 0.12) !important",
          cursor: "not-allowed",
          pointerEvents: "auto",
        },
        "&.MuiLoadingButton-loading": {
          color: "transparent !important",
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};
CustomLoadingButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  sx: PropTypes.object,
};
export default CustomLoadingButton;
