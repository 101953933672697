import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";

export const getTopThreats = createAsyncThunk(
  "dashboard/getTopThreats",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`/tid/dashboard/top-threats`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const addThreatfromTopThreat = createAsyncThunk(
  "dashboard/addThreatfromTopThreat",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/tid/dashboard/top-threats/add/${id}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const removeThreatfromTopThreat = createAsyncThunk(
  "dashboard/removeThreatfromTopThreat",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/tid/dashboard/top-threats/remove/${id}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getTopCapabilities = createAsyncThunk(
  "dashboard/getTopCapabilities",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/tid/dashboard/top-capabilities`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getThreatCategoriesType = createAsyncThunk(
  "dashboard/getThreatCategoriesType",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`/tid/dashboard/category-types`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getThreatCategoryDetails = createAsyncThunk(
  "dashboard/getThreatCategoryDetails",
  async ({ threat }, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(
        `/tid/dashboard/category?type=${threat}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getEffectiveSecurityPosture = createAsyncThunk(
  "dashboard/getEffectiveSecurityPosture",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosWrapper.get(`/tid/dashboard/posture`);
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
