import { createSlice } from "@reduxjs/toolkit";
import {
  getControlsByFreeText,
  getRemedeationsCompareControls,
  getTCCompactByTypeAndFilter,
  getThreatCoverage,
  saveThreatCoverage,
  updateThreatCoverage,
} from "../../../Services/TID/threatCoverage.service";

const initialState = {
  loading: false,
  searchLoading: false,
  threatCoverageData: [],
  threatCoverageCompactData: [],
  threatCoverageError: null,
};

const threatCoverageSlice = createSlice({
  name: "threatCoverage",
  initialState,
  reducers: {
    resetThreatCoverageData: (state) => ({
      ...state,
      loading: false,
      threatCoverageData: initialState.threatCoverageData,
      threatCoverageError: null,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getThreatCoverage.pending, (state) => ({
        ...state,
        loading: true,
        threatCoverageError: null,
      }))
      .addCase(getThreatCoverage.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        threatCoverageData: action.payload,
        threatCoverageError: null,
      }))
      .addCase(getThreatCoverage.rejected, (state, action) => ({
        ...state,
        loading: false,
        threatCoverageData: [],
        threatCoverageError: action.payload,
      }))
      .addCase(updateThreatCoverage.pending, (state) => ({
        ...state,
        loading: true,
        threatCoverageError: null,
      }))
      .addCase(updateThreatCoverage.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        threatCoverageData: action.payload,
        threatCoverageError: null,
      }))
      .addCase(updateThreatCoverage.rejected, (state, action) => ({
        ...state,
        loading: false,
        threatCoverageData: [],
        threatCoverageError: action.payload,
      }))
      .addCase(getTCCompactByTypeAndFilter.pending, (state) => ({
        ...state,
        loading: true,
        threatCoverageError: null,
      }))
      .addCase(getTCCompactByTypeAndFilter.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        threatCoverageCompactData: action.payload,
        threatCoverageError: null,
      }))
      .addCase(getTCCompactByTypeAndFilter.rejected, (state, action) => ({
        ...state,
        loading: false,
        threatCoverageCompactData: [],
        threatCoverageError: action.payload,
      }))
      .addCase(saveThreatCoverage.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(saveThreatCoverage.fulfilled, (state, action) => ({
        ...state,
        loading: false,
      }))
      .addCase(saveThreatCoverage.rejected, (state, action) => ({
        ...state,
        loading: false,
      }))
      .addCase(getRemedeationsCompareControls.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(getRemedeationsCompareControls.fulfilled, (state, action) => ({
        ...state,
        loading: false,
      }))
      .addCase(getRemedeationsCompareControls.rejected, (state, action) => ({
        ...state,
        loading: false,
      }))
      .addCase(getControlsByFreeText.pending, (state) => ({
        ...state,
        searchLoading: true,
      }))
      .addCase(getControlsByFreeText.fulfilled, (state, action) => ({
        ...state,
        searchLoading: false,
      }))
      .addCase(getControlsByFreeText.rejected, (state, action) => ({
        ...state,
        searchLoading: false,
      }));
  },
});
export const { resetThreatCoverageData } = threatCoverageSlice.actions;
export default threatCoverageSlice.reducer;

export const getThreatCoverageCompactData = (state) =>
  state.threatCoverage.threatCoverageCompactData;
export const getThreatCoverageLoading = (state) => state.threatCoverage.loading;
export const getControlSearchLoading = (state) =>
  state.threatCoverage.searchLoading;
