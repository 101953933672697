import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";

export const getSettings = createAsyncThunk("settings/getSettings", async (_, { rejectWithValue }) => {
    try {
        const response = await axiosWrapper.get(`/setting`);
        return response;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const saveSettings = createAsyncThunk(
    "settings/saveSettings",
    async ({ requestObject }, { rejectWithValue }) => {
        try {
            const response = await axiosWrapper.post(`/setting`, requestObject);
            return response;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
