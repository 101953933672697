import { Avatar, Box, IconButton, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useState } from "react";
import moment from "moment";
import { markNotificationAsRead } from "../../redux/Slice/Notifications/notificationsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomMarkdownPreview from "../../Components/Custom/CustomMarkdownPreview";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "rgba(17, 32, 56, 1)",
  color: "white",
  p: 3.2,
  borderRadius: "0.6rem",
  "&:focus-visible": {
    outline: "none !important",
  },
  height: "80%",
  overflow: "auto",
};

const NotificationModal = ({
  data,
  isRead,
  notificationIndex,
  toggleDrawer,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [selectedPropertyNameIndex, setSelectedPropertyNameIndex] = useState(0);

  const handleModal = () => {
    setOpenModal((pre) => !pre);
  };

  const handleSelecteProperty = (index) => {
    setSelectedPropertyNameIndex(index);
  };

  const getFormattedDateTime = (localDateTime) => {
    if (!localDateTime) {
      return "";
    }

    const dateTime = moment(localDateTime);
    const today = moment();

    if (dateTime.isSame(today, "day")) {
      return `Today, ${dateTime.format("hh:mm A")}`;
    } else if (dateTime.isSame(today.subtract(1, "days"), "day")) {
      return `Yesterday, ${dateTime.format("hh:mm A")}`;
    } else {
      return dateTime.format("DD-MM-YYYY");
    }
  };

  const selectedChange = data?.changes?.[selectedPropertyNameIndex];
  const oldValue = selectedChange?.oldValue;
  const propertyName = selectedChange?.propertyName;

  const currentChange = data?.changes?.[selectedPropertyNameIndex];
  const updatedValue = currentChange?.newValue;
  const currentProperty = currentChange?.propertyName;

  let content;

  if (oldValue) {
    if (propertyName === "description") {
      content = (
        <CustomMarkdownPreview
          source={oldValue}
          className="notification-content-box"
        />
      );
    } else {
      content = <Box className="notification-content-box">{oldValue}</Box>;
    }
  } else {
    content = <Box className="notification-content-box">Not available</Box>;
  }

  const renderUpdatedValue = () => {
    if (!updatedValue) {
      return <Box className="notification-content-box">Removed</Box>;
    }

    if (currentProperty === "description") {
      return (
        <CustomMarkdownPreview
          source={updatedValue}
          className="notification-content-box"
        />
      );
    }

    return <Box className="notification-content-box">{updatedValue}</Box>;
  };

  return (
    <>
      <Box
        className="notification-box"
        onClick={() => {
          handleModal();
          dispatch(markNotificationAsRead({ notificationIndex }));
        }}
      >
        <Avatar
          sx={{
            bgcolor: "#FFFFFF1F",
            border: "1.11px solid #FFFFFF29",
          }}
        >
          {data.name ? data.name.charAt(0).toUpperCase() : "E"}
        </Avatar>
        <Box className="notification-title">
          <p>
            <span>{data?.name}</span>&nbsp;has changed
          </p>
          <span className="notification-date">
            {getFormattedDateTime(data.localDateTime)}
          </span>
        </Box>
        <Box className="notification-indicator">
          {!isRead && (
            <svg
              width="8"
              height="9"
              viewBox="0 0 8 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="4" cy="4.5" r="4" fill="#0082F9" />
            </svg>
          )}
        </Box>
      </Box>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              borderBottom: "1px solid #1E2B40",
              paddingBottom: "0.5rem",
              justifyContent: "space-between",
              "&:focus-visible": {
                outline: "none",
              },
              "& span": {
                color: "#4bbd7d",
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h6"
              component="h2"
            >
              Changed in{""}
              <span
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (data?.entityId) {
                    handleModal();
                    toggleDrawer();
                    navigate(`/entity/${data?.entityId}`);
                  }
                }}
                onKeyDown={() => {}}
                aria-hidden
              >
                {data?.name}
              </span>
            </Typography>
            <IconButton onClick={handleModal}>
              <CloseOutlinedIcon
                sx={{ fill: "rgba(142, 151, 164, 1)", cursor: "pointer" }}
              />
            </IconButton>
          </Box>
          <Box className="notification-content-conatiner">
            <Box className="notification-content">
              <h3>Entity Id</h3>
              <Box className="notification-content-box">{data?.entityId}</Box>
            </Box>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Box
                className="notification-content"
                sx={{ paddingRight: "2rem", borderRight: "1px solid #2A3C57" }}
              >
                <h3>Name</h3>
                <Box className="notification-content-box">{data?.name}</Box>
              </Box>
              <Box
                className="notification-content"
                sx={{ paddingRight: "2rem", borderRight: "1px solid #2A3C57" }}
              >
                <h3>Type</h3>
                <Box className="notification-content-box">{data?.type}</Box>
              </Box>
              <Box className="notification-content">
                <h3>Date/Time</h3>
                <Box className="notification-content-box">
                  {data.localDateTime &&
                    `${moment(data.localDateTime).format(
                      "hh:mm A, DD-MM-YYYY"
                    )}`}
                </Box>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: "1.2rem",
                paddingBottom: "1rem",
                borderBottom: "1px solid #1E2B40",
              }}
            >
              Changes made in
            </Typography>
            <Box className="chages-tab-header">
              {Array.isArray(data.changes) &&
                data.changes.length > 0 &&
                data.changes.map((change, index) => {
                  const uniquekey = `${change.propertyName}-${index}`;
                  const isSelected = selectedPropertyNameIndex === index;
                  return (
                    <Box
                      key={uniquekey}
                      className={`notification-content-box ${
                        isSelected ? "selected" : ""
                      }`}
                      sx={{
                        background: "transparent !important",
                        border: "1px solid #2A3C57",
                        wordBreak: "normal",
                      }}
                      onClick={() => handleSelecteProperty(index)}
                    >
                      {change.propertyName}
                    </Box>
                  );
                })}
            </Box>
            <Box className="notification-content">
              <h3>Old value</h3>

              {content}
            </Box>
            <Box className="notification-content">
              <h3>New value</h3>
              {renderUpdatedValue()}
            </Box>
            <Box className="notification-content">
              <h3>Message Type</h3>
              <Box className="notification-content-box">
                {data?.messageType}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

NotificationModal.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    localDateTime: PropTypes.string,
    entityId: PropTypes.number,
    type: PropTypes.string,
    changes: PropTypes.arrayOf(
      PropTypes.shape({
        propertyName: PropTypes.string.isRequired,
        oldValue: PropTypes.oneOfType([PropTypes.string]),
        newValue: PropTypes.oneOfType([PropTypes.string]),
      })
    ),
    messageType: PropTypes.string,
  }),
  toggleDrawer: PropTypes.func.isRequired,
  isRead: PropTypes.bool,
  notificationIndex: PropTypes.number.isRequired,
};

export default NotificationModal;
