import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosWrapper } from "../../helper";

export const getThreatCoverage = createAsyncThunk(
  "threatCoverageData/getThreatCoverageData",
  async ({ selectedIds }, { rejectWithValue }) => {
    try {
      const requestIds =
        selectedIds &&
        selectedIds.length > 0 &&
        selectedIds.map((item) => item.id);
      const requestObject = { ids: requestIds };
      let response = await axiosWrapper.post(
        `tid/entity/threat-coverage`,
        requestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateThreatCoverage = createAsyncThunk(
  "threatCoverageData/updateThreatCoverageData",
  async ({ requestObject, threatCoverageId }) => {
    try {
      let response = await axiosWrapper.put(
        `tid/entity/threat-coverage/${threatCoverageId}`,
        requestObject
      );
      return response.data;
    } catch (error) {
      console.error("Failed to update threat coverage:", error);
      throw new Error(
        error.response?.data?.message ||
          "An error occurred while updating threat coverage."
      );
    }
  }
);

export const getTCCompactByTypeAndFilter = createAsyncThunk(
  "threatCoverageData/getTCCompactByTypeAndFilter",
  async (
    {
      selectedIds,
      top,
      type = "CONTROL",
      sortBy = "techniques",
      topTechniques,
    },
    { rejectWithValue }
  ) => {
    try {
      let requestUrl = `tid/entity/threat-coverage-compact?type=${type}&sort_by=${sortBy}`;
      if (top) {
        requestUrl = requestUrl + `&top=${top}`;
      }
      const requestIds =
        selectedIds &&
        selectedIds.length > 0 &&
        selectedIds.map((item) => item.id);
      let requestObject = { ids: requestIds };
      if (topTechniques) {
        requestObject = { ...requestObject, technique_ids: topTechniques };
      }
      let response = await axiosWrapper.post(requestUrl, requestObject);
      return response;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(
          error.response.data?.message ||
            "An error occurred while fetching data."
        );
      } else {
        return rejectWithValue("No response from the server.");
      }
    }
  }
);

export const saveThreatCoverage = createAsyncThunk(
  "threatCoverageData/saveThreatCoverage",
  async ({ requestObject, threatCoverageType }) => {
    try {
      let response = await axiosWrapper.put(
        `tid/entity/threat-coverage?type=${threatCoverageType}`,
        requestObject
      );
      return response;
    } catch (error) {
      console.error("Failed to save threat coverage:", error);
      throw new Error(
        error.response?.data?.message ||
          "An error occurred while saving threat coverage."
      );
    }
  }
);

export const getRemedeationsCompareControls = createAsyncThunk(
  "threatCoverageData/getRemedeationsCompareControls",
  async ({ selectedIds, controls }, { rejectWithValue }) => {
    try {
      const requestIds =
        selectedIds &&
        selectedIds.length > 0 &&
        selectedIds.map((item) => item.id);
      const requestObject = { ids: requestIds };
      let response = await axiosWrapper.post(
        `tid/entity/controls/sankey?controls=${controls}`,
        requestObject
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getControlsByFreeText = createAsyncThunk(
  "threatCoverageData/getControlsByFreeText",
  async ({ searchValue }, { rejectWithValue }) => {
    try {
      let response = await axiosWrapper.get(
        `/tid/entity/controls-summary?search=${searchValue}`
      );
      return response;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
