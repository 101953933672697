import { fetchAuthSession } from "aws-amplify/auth";
import axios from "axios";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_API_BASE_URL;
export const axiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const { idToken, accessToken } = (await fetchAuthSession()).tokens ?? {};
  config.headers["access-token"] = accessToken;
  config.headers["Authorization"] = `Bearer ${idToken}`;
  return config;
});

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      setTimeout(() => {
        toast("You are not authorized", { type: "error" });
      }, 1000);
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = `/login`;
    }

    if (!(error instanceof Error)) {
      const errorMessage =
        error?.message ||
        error?.response?.data?.message ||
        "Unknown error occurred";
      error = new Error(errorMessage);
    }

    return Promise.reject(new Error(error.message));
  }
);

export const axiosWrapper = {
  get: axiosGet,
  post: axiosPost,
  put: axiosPut,
  delete: axiosDelete,
};

async function axiosGet(url) {
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
}

async function axiosPost(url, body) {
  try {
    const response = await axiosInstance.post(url, body);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
}

async function axiosPut(url, body) {
  try {
    const response = await axiosInstance.put(url, body);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
}

async function axiosDelete(url) {
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    return handleError(error);
  }
}

function handleError(error) {
  if (error.response) {
    return Promise.reject(new Error(JSON.stringify(error.response.data)));
  } else if (error.request) {
    return Promise.reject(new Error("No response from the server"));
  } else {
    return Promise.reject(new Error("Request error: " + error.message));
  }
}
